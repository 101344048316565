<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="modal-body">
                                <div
                                    class="modal-body__content"
                                >
                                    <div class="modal-body__content-title">
                                        <span>
                                            {{ title }}
                                        </span>
                                        <i class="fa fa-times" @click="$emit('close')" />
                                    </div>
                                    <div
                                        v-if="state.preload"
                                        class="modal-body__content-body meeting-edit"
                                    >
                                        <Preloaders :count="10" />
                                    </div>
                                    <div
                                        v-else
                                        class="modal-body__content-body meeting-edit"
                                    >
                                        <div class="meeting-edit__base">
                                            <div class="meeting-edit__form">
                                                <!-- 実施日時 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        実施日時
                                                    </label>
                                                    <div class="form-content">
                                                        {{ removeSecond(milestone.meeting_start_at) }} 〜 {{ removeSecond(milestone.meeting_end_at?.split(' ')[1]) }}
                                                    </div>
                                                </div>
                                                <!-- アジェンダ -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        アジェンダ
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="milestone.agenda">
                                                            {{ milestone.agenda }}
                                                        </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- MTG資料(pdf/zip) -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        MTG資料(pdf/zip)<br />
                                                        <span class="alert">
                                                            ※30MBまで
                                                        </span>
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="milestone.files?.length">
                                                            <span
                                                                v-for="(row,i) in milestone.files"
                                                                :key="i"
                                                            >
                                                                <a :href="row.url" target="_blank" rel="noreferrer noopener">
                                                                    {{ row.name }}
                                                                </a>
                                                                {{ i !== milestone.files.length - 1 ? ', ' : ''}}
                                                            </span>
                                                       </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="meeting-edit__accodion">
                                            <div
                                                class="meeting-edit__subject"
                                                @click="state.collapsed = !state.collapsed"
                                            >
                                                <i class="fa fa-chevron-right" v-if="state.collapsed" />
                                                <i class="fa fa-chevron-down" v-else />
                                                MTG終了後
                                            </div>
                                            <div class="meeting-edit__form"
                                                :class="{ collapsed: state.collapsed }"
                                            >
                                                <!-- 終了予定日 -->
                                                <div class="form-ctrl" v-if="isKickoff">
                                                    <label class="form-title">
                                                      終了予定日
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="milestone.expected_completion_at">
                                                            {{ milestone.expected_completion_at.split(' ')[0] }}
                                                        </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- 企業参加者 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        企業参加者
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="milestone.organization_members">
                                                            {{ milestone.organization_members.join('、') }}
                                                       </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- 受講生参加者 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        受講生参加者
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="milestone.team_members">
                                                            {{ milestone.team_members.join('、') }}
                                                       </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- 議事録 -->
                                                <div class="form-ctrl">
                                                    <label class="form-title">
                                                        議事録
                                                    </label>
                                                    <div class="form-content">
                                                        <span v-if="milestone.note">
                                                            {{ milestone.note }}
                                                       </span>
                                                        <span v-else class="empty">
                                                            未入力
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- 介入希望 -->
                                                <div class="form-ctrl" v-if="isUser">
                                                    <label class="form-title">
                                                       介入希望
                                                    </label>
                                                    <div class="form-content">
                                                        <p>
                                                            {{ milestone.need_help ? '事務局の介入を希望する' : '事務局の介入を希望しない' }}
                                                        </p>
                                                        <p v-if="milestone.help_note" class="help_note">
                                                            {{milestone.help_note }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="modal-body__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            閉じる
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./MeetingConfirm.ts" />
<style lang="scss" src="./MeetingConfirm.scss" scoped></style>

