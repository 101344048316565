<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="project__question_response__container">
                <div class="project__question_response__container-title">
                  プロジェクトを終了し評価へ
                </div>
                <p class="project__question_response__container-subtitle">
                  作業が終わって成果物を受け取り、各チームリーダーと確認ができたら「終了するボタン」クリックしてプロジェクトを終了してください。※一度終了すると戻すことはできません。
                  <br/>
                  <br/>
                  終了後は各チームをそれぞれ評価するとプロジェクトは完了です。
                </p>
                <div class="project__question_response__container-button">
                  <button
                    @click="$emit('close')"
                    type="button"
                    class="btn-light-gray btn-md shadow"
                  >
                    キャンセル
                  </button>
                  <button
                    :disabled="state.isSubmitting"
                    :class="{ submitting: state.isSubmitting }"
                    type="button"
                    @click="onSubmit"
                    class="btn-blue btn-md shadow"
                  >
                    <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                    終了する
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="./ProjectConfirmCompleteManagement.ts" />
<link type="scss" scoped src="./ProjectConfirmCompleteManagement.scss" />
