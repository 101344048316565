<template>
  <div class="project_detail__container__body">
    <Preloaders v-if="state.preload"/>
    <div v-else>
      <div>
        <h4 class="project_detail_management__header">
          参加メンバー
        </h4>
        <AssignedTeams 
          :teamId="state.teamId"
          :isOwner="state.isOwner"
        />

        <h4 class="project_detail_management__header">
          プロジェクト管理
        </h4>

        <p class="project_detail_management__description">
          プロジェクトはチーム毎にMTGの設定が行われます。※同一開催の場合は同一日時が設定されます。<br>
          このチームとのすべてのタスクが終了し、成果物を確認したら、公開の可否を確認したのちに、「成果を受領する」ボタンをクリックしてください。本確認をもってプロジェクト完了となります。<span class="alert">※この作業は戻せませんのでご注意ください。</span><br>
        </p>

        <div v-if="state.preload || state.checkingAuth">
          <Preloaders :count="10"/>
        </div>
        <table
            v-else
            class="milestone_table"
        >
          <!-- キックオフ -->
          <tr>
            <td class="is-process">
            </td>
            <td class="is-title">
              キックオフ
            </td>
            <td class="is-date">
              <time v-if=state.kickOff>
                {{ state.kickOff?.meeting_start_at.split(" ")[0].replaceAll("-", "/") }}
                <br/>
                {{ removeSecond(state.kickOff?.meeting_start_at.split(" ")[1]) }}〜{{ removeSecond(state.kickOff?.meeting_end_at.split(" ")[1]) }}
              </time>
              <span v-else class="is-empty-text">
                未設定
              </span>
            </td>
            <td class="is-button">
              <button
                  class="btn-sm btn-blue"
                  :class="{ disabled: !state.kickOff }"
                  @click="state.selectedMilestone = state.kickOff; state.modalType = ModalType.Kickoff"
                  :disabled="!state.kickOff"
              >
                内容を確認
              </button>
            </td>
          </tr>
          <!-- 会議 -->
          <tr v-for="milestone in state.regulars" :key="milestone.id">
            <td class="is-process">
            </td>
            <td class="is-title">
              会議
            </td>
            <td class="is-date">
              <time v-if="milestone.meeting_start_at">
                {{ milestone.meeting_start_at.split(" ")[0].replaceAll("-", "/") }}
                <br/>
                {{ removeSecond(milestone.meeting_start_at.split(" ")[1]) }}〜{{ removeSecond(milestone.meeting_end_at.split(" ")[1]) }}
              </time>
              <span v-else class="is-empty-text">
                未設定
              </span>
            </td>
            <td class="is-button">
              <button
                  class="btn-sm btn-blue"
                  :class="{ disabled: state.step < MileStoneStep.kickoff }"
                  :disabled="state.step < MileStoneStep.kickoff"
                  @click="state.selectedMilestone = milestone; state.modalType = ModalType.Meeting"
              >
                内容を確認
              </button>
            </td>
          </tr>
          <!-- 最終報告 -->
          <tr v-if="state.review">
            <td class="is-process">
            </td>
            <td class="is-title">
              最終報告
            </td>
            <td class="is-date">
              <time v-if="state.review?.meeting_start_at">
                {{ state.review?.meeting_start_at.split(" ")[0].replaceAll("-", "/") }}
                <br/>
                {{ removeSecond(state.review?.meeting_start_at.split(" ")[1]) }}〜{{ removeSecond(state.review?.meeting_end_at.split(" ")[1]) }}
              </time>
              <span v-else class="is-empty-text">
                未設定
              </span>
            </td>
            <td class="is-button">
              <button
                  class="btn-sm btn-blue"
                  :class="{ disabled: state.step < MileStoneStep.meeting }"
                  :disabled="state.step < MileStoneStep.meeting"
                  @click="state.selectedMilestone = state.review; state.modalType = ModalType.MeetingLast"
              >
                内容を確認
              </button>
            </td>
          </tr>
          <!-- 成果提出 -->
          <tr>
            <td class="is-process">
            </td>
            <td class="is-title">
              成果提出
            </td>
            <td class="is-date">
              <time v-if="state.achievement">
                {{ state.achievement?.updated_at.split(" ")[0].replaceAll("-", "/") }}
              </time>
            </td>
            <td class="is-button">
              <button
                  class="btn-sm btn-blue"
                  :class="{ disabled: !state.achievement }"
                  :disabled="!state.achievement"
                  @click="state.modalType = ModalType.Achievement"
              >
                {{ state.achievement && state.achievement.browsed_by_organization ? "受領済み" : "内容を確認" }}
              </button>
            </td>
          </tr>
          <!-- 評価 -->
          <!-- 2022年度1タームは非表示  -->
          <tr v-if="false">
            <td class="is-process">
            </td>
            <td class="is-title">
              評価（任意）
            </td>
            <td class="is-date">
              <time v-if="state.votingDate">
                {{ state.votingDate }}
              </time>
            </td>
            <td class="is-button">
              <button
                  v-if="!contractorInfo?.is_voted && state.isOwner"
                  class="btn-sm btn-blue"
                  :class="{ disabled: state.step < MileStoneStep.confirmed || contractorInfo?.is_voted }"
                  :disabled="state.step < MileStoneStep.confirmed || contractorInfo?.is_voted"
                  @click="state.modalType = ModalType.Rating"
              >
                チームを評価する
              </button>
              <div class="rate_label" v-else-if="contractorInfo?.is_voted" >
                評価済み
              </div>
              <div class="rate_label" v-else >
                評価前
              </div>
            </td>
          </tr>
        </table>

      </div>
    </div>
    <MeetingConfirmPopup
        v-if="showMeetingPopup"
        :type="state.modalType"
        :milestone="state.selectedMilestone"
        :teamName="contractorInfo?.team_name"
        @close="state.selectedMilestone = null; state.modalType = null"
    />
    <AchievementConfirmPopup
        v-if="state.modalType === ModalType.Achievement"
        :achievement="state.achievement"
        :teamName="contractorInfo?.team_name"
        @close="state.modalType = null"
        @complete="updateAchievement"
        @browsed="state.achievement.browsed_by_organization = true; state.step = MileStoneStep.confirmed; state.showAlertBrowsedAchievement = true;"
    />
    <RatingEditPopup
        v-if="state.modalType === ModalType.Rating"
        :teamInfo="contractorInfo"
        @complete="contractorInfo.is_voted = true; state.votingDate = todayStr()"
        @close="state.modalType = null;"
    />
    <Alert v-if="state.showAlertBrowsedAchievement" content="成果物を承認しました。" @close="state.showAlertBrowsedAchievement = false;"/>
  </div>
</template>

<script lang="ts" src="./ProjectContractorManagement.ts"></script>
<style lang="scss" src="./ProjectContractorManagement.scss" scoped></style>
