import { computed, defineComponent, reactive } from "vue";
import { ModalType } from "@/presentation/views/organization/project-detail/management/ProjectDetailManagement";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import CommonService from "@/services/CommonService";
import { useStore } from "vuex";
export default defineComponent({
    name: "MeetingConfirmPopup",
    components: {
        Preloaders: Preloaders
    },
    props: {
        type: String,
        milestone: Object,
        teamName: {
            type: String,
            required: false,
            default: null
        },
    },
    setup: function (props) {
        var store = useStore();
        var state = reactive({
            teamInfo: null,
            collapsed: false,
            preload: true
        });
        var isKickoff = props.type === ModalType.Kickoff;
        var isUser = computed(function () { return CommonService.isUser(); });
        var title = computed(function () {
            var typeText = (function () {
                var _a, _b;
                if (isKickoff)
                    return 'キックオフ';
                if (state.preload)
                    return '...';
                if (((_a = props.milestone) === null || _a === void 0 ? void 0 : _a.meeting_type) === 'REGULAR')
                    return '会議';
                if (((_b = props.milestone) === null || _b === void 0 ? void 0 : _b.meeting_type) === 'REVIEW')
                    return '最終報告';
                return '未設定';
            })();
            var tmpTeamName = (function () {
                var _a;
                if (CommonService.isUser()) {
                    return ((_a = store.state.teamInfo) === null || _a === void 0 ? void 0 : _a.team_name) || '...';
                }
                else {
                    return props.teamName || '...';
                }
            })();
            return typeText + " / " + tmpTeamName;
        });
        function removeSecond(time) {
            var hms = time.split(":");
            if (hms && hms.length > 2) {
                return hms[0] + ":" + hms[1];
            }
            else {
                return time;
            }
        }
        state.preload = false;
        return {
            title: title,
            state: state,
            isKickoff: isKickoff,
            isUser: isUser,
            removeSecond: removeSecond,
        };
    }
});
