<template>
  <Preloaders v-if="state.preload" />
  <div v-else>
    <table
      class="assigned_teams__table"
      v-for="(team, tidx) in state.contractors"
      :key="tidx"
    >
      <caption>
        <router-link :to="RoutePath.TEAMS + '/' + team.team_id">
          {{ team.team_name }}
        </router-link>
      </caption>
      <tr v-for="(row,i) in team.members" :key="i">
        <td>
          <router-link :to="`${RoutePath.USER_PROFILE}/${row.user_account_id}/info`">{{ row.user_nickname }}</router-link>
          <span v-if="row.is_leader"> / リーダー</span>
        </td>
        <td>
          <span v-for="(row, i) in row.user_occupations" :key="i">
            {{ row.name }}
            {{ i < row.user_occupations?.length - 1 ? '、' : '' }}
          </span>
        </td>
        <td
          v-if="isOrganization && i === 0"
          :rowspan="team.members.length"
          class="is-btn-group"
        >
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts" src="./AssignedTeams.ts"></script>
<style lang="scss" src="./AssignedTeams.scss" scoped></style>
