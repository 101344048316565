import { reactive, defineComponent, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import ConfirmCompletePopup from "@/presentation/components/modals/project-confirm-complete-management/ProjectConfirmCompleteManagement.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import StarProfile from '@/presentation/components/star-profile/StarProfile.vue';
import RoutePath from "@/commons/RoutePath";
import CommonService from "@/services/CommonService";
import { getCurrentAccountId } from "@/commons/UserStatusUtis";
export default defineComponent({
    name: "AssignedTeams",
    components: {
        Preloaders: Preloaders,
        StarProfile: StarProfile,
        ConfirmCompletePopup: ConfirmCompletePopup,
        Alert: Alert,
    },
    props: {
        teamId: {
            type: Number,
            required: false,
            default: null
        },
        isOwner: Boolean
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var route = useRoute();
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var state = reactive({
            projectId: Number(route.params.id),
            preload: true,
            contractors: [],
            showConfirmComplete: false,
            showCompleteAlert: false,
            projectCompleted: false,
            isOrganizationRated: false
        });
        var isOrganization = computed(function () { return store.state.headerMode === 'organizationLogged'; });
        var projectInfo = computed(function () { return store.state.projectInfo; });
        onMounted(function () {
            getContractor();
        });
        function getContractor() {
            organizationProjectInteractor.getContractors(state.projectId).then(function (result) {
                emit('load');
                if (!result.data || result.data.length == 0) {
                    state.preload = false;
                    store.dispatch('detectProjectContractorStatus', []);
                    return;
                }
                if (props.teamId !== null) {
                    state.contractors = result.data.filter(function (team) { return team.team_id === props.teamId; });
                }
                else {
                    state.contractors = result.data;
                }
                state.preload = false;
                store.dispatch('detectProjectContractorStatus', result.data);
                // 企業評価済み判定
                if (CommonService.isUser()) {
                    var accountId_1 = getCurrentAccountId();
                    state.isOrganizationRated = !!result.data.find(function (row) {
                        var _a;
                        var isMatched = !!((_a = row.members) === null || _a === void 0 ? void 0 : _a.find(function (member) { return member.user_account_id === accountId_1; }));
                        return isMatched && row.is_rated;
                    });
                }
            }).catch(function (error) {
                emit('load');
                state.preload = false;
            });
        }
        return {
            isOrganization: isOrganization,
            props: props,
            projectInfo: projectInfo,
            state: state,
            RoutePath: RoutePath,
        };
    }
});
