import { reactive, defineComponent, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import DIContainer from "@/core/DIContainer";
import MeetingConfirmPopup from "@/presentation/components/modals/meeting-confirm/MeetingConfirm.vue";
import AchievementConfirmPopup from "@/presentation/components/modals/achievement-confirm/AchievementConfirm.vue";
import RatingEditPopup from "@/presentation/components/modals/rating-edit/RatingEdit.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import RoutePath from "@/commons/RoutePath";
import CommonService from "@/services/CommonService";
import router from "@/presentation/router";
import MilestoneInteractor from "@/domain/usecases/MilestoneInteractor";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import DeliverableInteractor from "@/domain/usecases/DeliverableInteractor";
import AssignedTeams from "@/presentation/components/assigned-teams/AssignedTeams.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
export var ModalType;
(function (ModalType) {
    ModalType["Kickoff"] = "Kickoff";
    ModalType["Meeting"] = "Meeting";
    ModalType["MeetingAdd"] = "MeetingAdd";
    ModalType["MeetingLast"] = "MeetingLast";
    ModalType["Achievement"] = "Achievement";
    ModalType["Rating"] = "Rating";
})(ModalType || (ModalType = {}));
export var MileStoneStep;
(function (MileStoneStep) {
    MileStoneStep[MileStoneStep["init"] = 1] = "init";
    MileStoneStep[MileStoneStep["kickoff"] = 2] = "kickoff";
    MileStoneStep[MileStoneStep["meeting"] = 3] = "meeting";
    MileStoneStep[MileStoneStep["review"] = 4] = "review";
    MileStoneStep[MileStoneStep["confirmed"] = 5] = "confirmed";
})(MileStoneStep || (MileStoneStep = {}));
export default defineComponent({
    name: "ProjectContractorManagement",
    components: {
        Preloaders: Preloaders,
        MeetingConfirmPopup: MeetingConfirmPopup,
        AchievementConfirmPopup: AchievementConfirmPopup,
        RatingEditPopup: RatingEditPopup,
        Alert: Alert,
        AssignedTeams: AssignedTeams,
    },
    setup: function () {
        var store = useStore();
        var route = useRoute();
        var state = reactive({
            projectId: Number(route.params.id),
            teamId: Number(route.params.team_id),
            preload: true,
            checkingAuth: false,
            modalType: null,
            step: MileStoneStep.meeting,
            kickOff: null,
            regulars: [],
            review: null,
            selectedMilestone: null,
            achievement: null,
            votingDate: "",
            isOwner: false,
            showAlertBrowsedAchievement: false
        });
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var isStepFinishedReview = function () {
            return state.review !== null && !!state.review.note;
        };
        function updateAchievement(achievement) {
            state.achievement = achievement;
        }
        function todayStr() {
            var now = new Date();
            return now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate();
        }
        function removeSecond(time) {
            var hms = time.split(":");
            if (hms && hms.length > 2) {
                return hms[0] + ":" + hms[1];
            }
            else {
                return time;
            }
        }
        var contractorInfo = computed(function () { return store.state.projectContractors.find(function (contractor) { return contractor.team_id === state.teamId; }); });
        var projectInfo = computed(function () { return store.state.projectInfo; });
        var showMeetingPopup = computed(function () {
            return state.modalType === ModalType.Kickoff ||
                state.modalType === ModalType.Meeting ||
                state.modalType === ModalType.MeetingAdd ||
                state.modalType === ModalType.MeetingLast;
        });
        // 404処理
        watch(function () { return store.state.projectInfo; }, function (data) {
            // プロジェクト公開前・停止は見れない
            if (data.progress_status === 'temp_saved' ||
                data.progress_status === 'stop') {
                router.push(RoutePath.PAGE_404);
            }
        });
        watch(function () { return [store.state.projectInfo, store.state.organizationMenuInfo, contractorInfo.value]; }, function (info) {
            var _a, _b, _c;
            // 人材側は見られない
            if (CommonService.isUser()) {
                router.push(RoutePath.PAGE_404);
                return;
            }
            // 読み込み中
            if (!((_a = info[0]) === null || _a === void 0 ? void 0 : _a.project_id) || !((_b = info[1]) === null || _b === void 0 ? void 0 : _b.account_id) || !((_c = info[2]) === null || _c === void 0 ? void 0 : _c.team_id)) {
                state.checkingAuth = true;
                return;
            }
            // 異なる企業は見られない
            if (CommonService.isOrganization()
                && info[0].project_owner_account_id !== info[1].account_id) {
                router.push(RoutePath.PAGE_404);
                return;
            }
            else {
                state.checkingAuth = false;
            }
        });
        watch(function () { return contractorInfo.value; }, function (team) {
            var userInteractor = DIContainer.instance.get(UserInteractor);
            if (!team) {
                return;
            }
            var leader = team.members.find(function (member) { return !!member.is_leader; });
            userInteractor
                .getMyRating(leader.user_account_id, 1, 100)
                .then(function (res) {
                if (!res) {
                    return;
                }
                for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                    var rate = _a[_i];
                    if (rate.project_id === state.projectId) {
                        state.votingDate = rate.date;
                    }
                }
            });
        });
        watch(function () { return route.params.team_id; }, function (team_id) {
            if (!team_id) {
                return;
            }
            state.teamId = Number(team_id);
            state.preload = true;
            onChangeTeam();
        });
        var onChangeTeam = function () {
            // データ初期化
            state.modalType = null;
            state.step = MileStoneStep.meeting;
            state.kickOff = null;
            state.regulars = [];
            state.review = null;
            state.selectedMilestone = null;
            state.achievement = null;
            state.votingDate = "";
            // マイルストーン一覧取得
            var milestoneInteractor = DIContainer.instance.get(MilestoneInteractor);
            milestoneInteractor.getMilestones(state.teamId).then(function (response) {
                var kickoff = [];
                var regular = [];
                var review = [];
                var projectMilestone = response.data.filter(function (milestone) { return milestone.project_id === state.projectId; });
                for (var _i = 0, projectMilestone_1 = projectMilestone; _i < projectMilestone_1.length; _i++) {
                    var milestone = projectMilestone_1[_i];
                    switch (milestone.meeting_type) {
                        case "KICKOFF": {
                            kickoff.push(milestone);
                            break;
                        }
                        case "REGULAR": {
                            regular.push(milestone);
                            break;
                        }
                        case "REVIEW": {
                            review.push(milestone);
                            break;
                        }
                    }
                }
                state.kickOff = kickoff.length > 0 ? kickoff[0] : null;
                state.regulars = regular;
                state.review = review.length > 0 ? review[0] : null;
                if (isStepFinishedReview()) {
                    state.step = MileStoneStep.review;
                }
                // 成果物状態確認
                var deliverableInteractor = DIContainer.instance.get(DeliverableInteractor);
                return deliverableInteractor.getDeliverable(state.projectId, state.teamId);
            }).then(function (deliverableResponse) {
                var _a;
                if (!Array.isArray(deliverableResponse.data) && ((_a = deliverableResponse.data) === null || _a === void 0 ? void 0 : _a.browsed_by_organization)) {
                    state.step = MileStoneStep.confirmed;
                }
                state.achievement = Array.isArray(deliverableResponse.data) ? null : deliverableResponse.data;
                state.preload = false;
                // 直リンクによるモーダル表示
                var milestoneId = route.query.milestone && Number(route.query.milestone);
                var achievementId = route.query.achievement && Number(route.query.achievement);
                if (milestoneId) {
                    if (state.kickOff && state.kickOff.id === milestoneId) {
                        state.selectedMilestone = state.kickOff;
                        state.modalType = ModalType.Kickoff;
                    }
                    var regular = state.regulars.find(function (m) { return m.id === milestoneId; });
                    if (regular) {
                        state.selectedMilestone = regular;
                        state.modalType = ModalType.Meeting;
                    }
                    if (state.review && state.review.id === milestoneId) {
                        state.selectedMilestone = state.review;
                        state.modalType = ModalType.MeetingLast;
                    }
                }
                else if (achievementId && state.achievement && achievementId === state.achievement.id) {
                    state.modalType = ModalType.Achievement;
                }
            }).catch(function (e) {
                console.error(e);
                state.preload = false;
            });
        };
        function checkIsowner() {
            organizationProjectInteractor.checkOwner(null).then(function (result) {
                if (result.data === true) {
                    state.isOwner = true;
                }
            }).catch(function (error) {
                state.preload = false;
            });
        }
        onMounted(function () {
            onChangeTeam();
            checkIsowner();
        });
        return {
            state: state,
            RoutePath: RoutePath,
            ModalType: ModalType,
            MileStoneStep: MileStoneStep,
            showMeetingPopup: showMeetingPopup,
            contractorInfo: contractorInfo,
            projectInfo: projectInfo,
            todayStr: todayStr,
            updateAchievement: updateAchievement,
            removeSecond: removeSecond,
        };
    }
});
