import { __assign } from "tslib";
import { computed, defineComponent, onMounted, reactive } from "vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import CommonService from "@/services/CommonService";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import DIContainer from "@/core/DIContainer";
import DeliverableInteractor from "@/domain/usecases/DeliverableInteractor";
import { useStore } from "vuex";
export default defineComponent({
    name: "AchievementConfirmPopup",
    components: {
        Preloaders: Preloaders,
        ErrorMessage: ErrorMessage
    },
    props: {
        achievement: Object,
        teamName: {
            type: String,
            required: false,
            default: null
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            source: {},
            preload: true,
            isSubmitting: false,
            publish: 1,
            publish_to_badge: 1,
            project_name_after_change: undefined,
            errors: {},
        });
        var store = useStore();
        var isOrganization = CommonService.isOrganization();
        // - - -
        // actions
        onMounted(function () {
            var _a;
            state.preload = false;
            if ((_a = props.achievement) === null || _a === void 0 ? void 0 : _a.browsed_by_organization) {
                state.publish = props.achievement.publish;
                state.publish_to_badge = props.achievement.publish_to_badge;
                state.project_name_after_change = props.achievement.project_name_after_change;
            }
        });
        var title = computed(function () {
            var tmpTeamName = (function () {
                var _a;
                if (CommonService.isUser()) {
                    return ((_a = store.state.teamInfo) === null || _a === void 0 ? void 0 : _a.team_name) || '...';
                }
                else {
                    return props.teamName || '...';
                }
            })();
            return "\u6210\u679C\u63D0\u51FA / " + tmpTeamName;
        });
        var publishList = [
            { id: 1, name: '公開を許可する' },
            { id: 2, name: '一部に修正を加えることで公開を許可する' },
            { id: 3, name: '公開は許可しない' },
        ];
        function confirmAchievement() {
            var _a;
            state.isSubmitting = true;
            var deliverableInteractor = DIContainer.instance.get(DeliverableInteractor);
            deliverableInteractor.browseByOrganization((_a = props.achievement) === null || _a === void 0 ? void 0 : _a.id, state.publish, state.publish_to_badge, state.project_name_after_change)
                .then(function () {
                state.isSubmitting = false;
                emit('close');
                emit('complete', __assign(__assign({}, props.achievement), { publish: state.publish, publish_to_badge: state.publish_to_badge, project_name_after_change: state.project_name_after_change }));
                emit('browsed');
            }).catch(function (e) {
                console.error(e);
                alert("更新に失敗しました。");
                state.isSubmitting = false;
                state.errors = e.validation_errors;
            });
        }
        return {
            state: state,
            title: title,
            isOrganization: isOrganization,
            confirmAchievement: confirmAchievement,
            publishList: publishList
        };
    }
});
